/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_SERVICE = gql`
    mutation AddEditService($serviceID:ID,$categoryID:Int,$photo:Upload, $langs:[ServiceLangsInput],$selectedServicesToBuy:[ID]){
        addEditService(serviceID:$serviceID, categoryID:$categoryID,photo:$photo,langs:$langs,selectedServicesToBuy:$selectedServicesToBuy){
            serviceID
            name
            price
            lang
        }
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_SERVICE_PRIORITY = gql`
    mutation updateServicePriority($serviceID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateServicePriority(serviceID:$serviceID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_SERVICE = gql`
    query ServiceWithLangs($serviceID: ID!, $lang:String){
        serviceWithLangs(serviceID:$serviceID){
            serviceID
            photo
            langs{
                lang
                name
                price
                discountPrice
                emailText
            }   
            servicesToBuy{
                buyServiceID 
             }

        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allServices(lang: $lang){
            serviceID
            name
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_SERVICES = gql`
    query AllServices($lang: String!){
        allServices(lang: $lang){
            serviceID
            name
            price
            lang
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_SERVICES = gql`
    mutation deleteServices($serviceIDs:ID!) {
        deleteServices(serviceIDs:$serviceIDs)
        reindexPriority
    }
`;

/**
 * vyhledání služeb
 */
export const SEACRH_SERVICES = gql`

    query SearchServices($text: String!,$lang:String){
        searchServices(text:$text,lang:$lang){
            name
            serviceID
        }

    }

`;