import React from "react";
import { GET_ADMIN_USER_CONTEXT } from "../Queries/login";
import { Query } from "react-apollo";
import Loading from "../../../GlobalComponents/Loading";

export const AuthContext = React.createContext();

class AuthProvider extends React.Component {

    constructor(props){
        super(props);        
    }

    render() {
        
        return (

            <Query 
                query={GET_ADMIN_USER_CONTEXT} 
                errorPolicy="all"
            >
            {
                ({data,loading,error,refetch }) => {
                    
                    if(loading) return (<Loading />);

                    if(!data.adminUserContext)
                        window.location.href = "/";
                    
                    return(
                        <AuthContext.Provider value={data.adminUserContext}>
                            {this.props.children}
                        </AuthContext.Provider>
                    )
                }}
            </Query>
        );
    }
}

export default AuthProvider;