import React,{ Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import edit from '../../../Public/Images/edit.svg';
import copy from '../../../Public/Images/copy_2.svg';
import contentManager from '../../../Public/Images/content_manager.svg';
import move from '../../../Public/Images/move.svg';
import DeleteLink from './DeleteLink';
import {COPY_LINK} from '../Queries/index';
import Helper from '../../../Library/helper';
import {withApollo,useMutation } from 'react-apollo';
import Notification from '../../../Library/notification';

const SubLink = (props) => {

    const [Copy,{loading, error}] = useMutation(COPY_LINK,{
        update:(cache, response) => {

            props.refetchLInks();

            let notify = new Notification();   
            notify.setNotification(null,"Joo, mám dvojče :)",true,false,props.client);
            
        },
        onError: (error) => {

            var helper = new Helper();
            let notify = new Notification();   
            notify.setNotification(null,helper.getApolloErrorText(error),false,true,props.client);
            
        }
    });

    const onDragEnd = (result,updateLinkPriority) => {

        const IDs = result.draggableId.split("-");

        updateLinkPriority({
            variables:{
                parentID: IDs[1],
                linkID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    const CopyLink = (linkID) =>{
        Copy({
            variables:{
                linkID:linkID
            }
        })
    }

    return(
        
        <DragDropContext onDragEnd={(result) => onDragEnd(result,props.updateLinkPriority)}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                        {props.links && props.links.map((item,index) => {
                            
                            if(item.linkID){
                            return (
                                <Draggable key={index} draggableId={item.linkID+"-"+item.parentID} index={index}>
                                    {(provided, snapshot) => (
                                    <div className="data-list-item" 
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <div className="data-list-item-content categories">
                                            <div className="cross" style={(props.level == 0 ? {paddingLeft:10}: {paddingLeft:props.level * 20 + 10})}>
                                                <img className="move-icon" src={move} />
                                                {item.name}
                                            </div>
                                            <div className="text-center">{(item.active == 1 ? "Ano" : "Ne" )}</div>
                                            <div className="text-center">{(item.inHeader == 1 ? "Ano" : "Ne" )}</div>
                                            <div className="text-center">{(item.inFooter == 1 ? "Ano" : "Ne" )}</div>
                                            <div className="text-center">{(item.hideMenu == 1 ? "Ano" : "Ne" )}</div> 
                                            <div className="text-right">
                                                <img title="Upravit odkaz" onClick={() => props.openCloseModal(true,item.linkID)} className="edit-icon" src={edit} /> 
                                                <img title="Kopírovat odkaz" onClick={() => CopyLink(item.linkID)} className="content-icon mr-1" src={copy} /> 
                                                <DeleteLink linkID={item.linkID} parentID={item.parentID} subLink={item.subLink} deleteLink={props.deleteLink} />
                                            </div>
                                        </div>
                                        {item.subLink && <SubLink openCloseModal={props.openCloseModal} openContentManager={props.openContentManager} deleteLink={props.deleteLink} updateLinkPriority={props.updateLinkPriority} links={item.subLink} level={props.level + 1} />}
                                    </div>
                                    )}
                                </Draggable>
                            )
                            }else{
                                return "";
                            }
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>

    )
}

export default withApollo(SubLink);