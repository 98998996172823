/*
    Přidání potahu
*/
import React,{ Component, Fragment } from 'react';
import {Mutation,Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_CUSTOMER} from '../Queries/customer.js';
import { withApollo } from 'react-apollo';
import CustomerLib from '../Library/customer';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic';
import Invoices from './Invoice';

const INITIAL_STATE = {
    apolloError:"",
    selectedCustomerID:null,
    allCountries:[],
    allDomains:[],
    allMembershipDurations:[],
    selectedTab:1,
    formData:{
        customerID:0,
        name:"",
        company:"",
        surname:"",
        email:"",
        tel:"",
        street:"",
        city:"",
        zip:"",
        countryID:0,
        description:"",
        autoPayments:1,
        membershipDuration:0,
        domainID:0
    }
}

class AddEditCustomer extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount(){

        this.customer = new CustomerLib(this); 
        this.customer.getData();
        
    }

    showTab(e,tab){

        e.preventDefault();

        this.setState({
            selectedTab:tab
        }); 
    }
    
    render(){

        const {selectedCustomerID,apolloError,allCountries,allDomains,allMembershipDurations,selectedTab} = this.state;
        return(

            <Mutation 
                mutation={ADD_EDIT_CUSTOMER}
                update = {async (cache, response) => {

                    this.customer.updateCustomerList(cache,response,selectedCustomerID,this.props.customerListVariables);

                    let notify = new Notification();

                    if(selectedCustomerID && selectedCustomerID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);
                    
                }}

                onCompleted = {(response) => {
                    this.props.openCloseModal(false);   
                }}
                
            >
            {
                (addCustomer,{data,loading,error}) => {

                    const {
                        name,surname,email,tel,street,city,zip,countryID,description,autoPayments,membershipDuration,domainID
                    } = this.state.formData;

                    if(error){
                        const helper = new Helper(); 
                        error = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{(selectedCustomerID && selectedCustomerID != 0 ? "Úprava zákazníka" : "Přidání zákazníka")}</h4>
                                    <button type="button" className="close" onClick={() => this.props.openCloseModal(false)}>
                                    <span aria-hidden="true">x</span>
                                    <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div>
                                        {!loading ?
                                            <Fragment>
                                                {error || apolloError ?
                                                    <Error text={error || apolloError} />
                                                :
                                                    <div>

                                                        <ul className="nav nav-tabs" role="tablist">
                                                            <li className="nav-item">
                                                                <a className={(selectedTab == 1 ? "nav-link active" : "nav-link")} href="#main" onClick={(e) => this.showTab(e,1)}>Základní údaje</a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className={(selectedTab == 2 ? "nav-link active" : (selectedCustomerID ? 'nav-link' : 'nav-link disabled'))} href="#invoices" onClick={(e) => {e.preventDefault();if(selectedCustomerID)this.showTab(e,2)}}>Platby a Faktury</a>
                                                            </li>

                                                        </ul>

                                                        <div className="tab-content" id="myTabContent">
                                                            {selectedTab == 1 &&
                                                                <div className="row">
                                                                                                                            
                                                                    <div className="col-12 col-sm-6">
                                                                        <label className="input-label">Jméno</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="name" value={name} onChange={(e) => this.customer.formHandle(e)} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <label className="input-label">Příjmení</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="surname" value={surname} onChange={(e) => this.customer.formHandle(e)} />
                                                                        </div>
                                                                    </div>

                                                                    

                                                                    <div className="col-12 col-sm-4">
                                                                        <label className="input-label">Ulice</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="street" value={street} onChange={(e) => this.customer.formHandle(e)} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-2">
                                                                        <label className="input-label">PSČ</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="zip" value={zip} onChange={(e) => this.customer.formHandle(e)} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-3">
                                                                        <label className="input-label">Město</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="city" value={city} onChange={(e) => this.customer.formHandle(e)} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-3">
                                                                        <label className="input-label">Stát</label>
                                                                        <div className="form-group">
                                                                            <select className="form-control" name="countryID" value={countryID} onChange={(e) => this.customer.formHandle(e)}>
                                                                                <option value="0"> --- vyberte stát --- </option>  
                                                                                {allCountries && allCountries.map((item,index) => (
                                                                                    <option key={index} value={item.countryID}> {item.name} </option> 
                                                                                ))}  
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <label className="input-label">Email</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="email" value={email} onChange={(e) => this.customer.formHandle(e)} />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-6">
                                                                        <label className="input-label">Tel.</label>
                                                                        <div className="form-group">
                                                                            <input className="form-control" type="text" name="tel" value={tel} onChange={(e) => this.customer.formHandle(e)} />
                                                                        </div>
                                                                    </div>

                                                                    {!selectedCustomerID &&
                                                                        <div className="col-12 col-sm-3">
                                                                            <label className="input-label">Členství</label>
                                                                            <div className="form-group">
                                                                                <select className="form-control" name="membershipDuration" value={membershipDuration} onChange={(e) => this.customer.formHandle(e)}>
                                                                                    <option value="0"> --- vyberte členství --- </option>  
                                                                                    {allMembershipDurations && allMembershipDurations.map((item,index) => (
                                                                                        <option key={index} value={item.membershipDurationID}> {item.name} </option> 
                                                                                    ))}  
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <div className="col-12 col-sm-3">
                                                                        <label className="input-label">Automatické platby</label>
                                                                        <div className="form-group">
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="autoPayments" id="autoPayments1" value="1" checked={(autoPayments == 1 ?  true : false)} onChange={(e) => this.customer.formHandle(e)} />
                                                                                <label className="form-check-label" htmlFor="autoPayments1">
                                                                                    Ano
                                                                                </label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="autoPayments" id="autoPayments2" value="0" checked={(autoPayments == 0 ?  true : false)} onChange={(e) => this.customer.formHandle(e)} />
                                                                                <label className="form-check-label" htmlFor="autoPayments2">
                                                                                    Ne
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-sm-3">
                                                                        <label className="input-label">Přiřadit k doméně</label>
                                                                        <div className="form-group">
                                                                            <select className="form-control" name="domainID" value={domainID} onChange={(e) => this.customer.formHandle(e)}>
                                                                                <option value="0"> --- vyberte doménu --- </option>  
                                                                                {allDomains && allDomains.map((item,index) => (
                                                                                    <option key={index} value={item.domainID}> {item.domainName} </option> 
                                                                                ))}  
                                                                            </select>
                                                                        </div>
                                                                    </div>

                    
                                                                    <div className="col-12">
                                                                        <label className="input-label">Interní poznámka</label>
                                                                        <div className="form-group">
                                                                            <TinyMCEBasic 
                                                                                name="description"
                                                                                value={(description ? description : "")}
                                                                                OnEditorChange={(data) => this.customer.setDescription(data)}
                                                                            />
                                                                            
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            }
                                                            {selectedTab == 2 &&
                                                            
                                                                <Invoices customerID = {selectedCustomerID} />

                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </Fragment>
                                        :
                                        <Loading />  
                                        }  

                                    </div>
               
                                </div>
                                <div className="modal-footer"> 
                                
                                    <button type="button" className="btn btn-primary flex-fill" onClick={() => this.customer.addCustomer(addCustomer)}>{(selectedCustomerID && selectedCustomerID != 0 ? "Upravit" : "Přidat")}</button>
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditCustomer);