/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component,Fragment } from 'react';
import { withApollo } from 'react-apollo';
import {Query} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_CUSTOMERS_INVOICES} from '../Queries/customer.js';
import notes from '../../../Public/Images/notes.svg';
import Helper from '../../../Library/helper';
import {SERVER_URL} from '../../../Config/index';

const INITIAL_STATE = {
    customerListVariables:{
        limit:50,
        offset:0,
    }
}

class Customer extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;
    }

    render(){

        const {customerListVariables} = this.state;
        const {customerID} = this.props;

        return(
         
            <Query 
                query={GET_CUSTOMERS_INVOICES}
                variables = {{
                    offset:customerListVariables.offset,
                    limit:customerListVariables.limit,
                    customerID
                }}
                fetchPolicy = {'network-only'}
            >
                {({data,loading,error,fetchMore }) => {
                    
                    if(loading) return (<Loading />);
                    if(error){
                        const helper = new Helper(); 
                        return (<Error text={helper.getApolloErrorText(error)} />);
                    }

                    return(
                        <div className="card main">
                            <div className="card-header d-flex align-items-center"></div>
                            <div className="card-body">
                
                                <div className="data-list">
                                    
                                    <div className="data-list-item header">
                                        <div className="data-list-item-content">
                                            <div className="">Číslo faktury</div>
                                            <div className="text-center static">Datum vložení</div>
                                            <div className="text-center static">Datum splatnosti</div>
                                            <div className="text-center static">Celková částka</div>
                                            <div className="text-right static small">Možnosti</div>
                                        </div>
                                    </div>
                                    
                                    {data.allCustomerInvoices && data.allCustomerInvoices.map((item,index) => {
                                                                                                    
                                        return (
                                        
                                            <div key={index} className="data-list-item-content">
                                                <div className="">{item.invoiceNumber}</div>
                                                <div className="text-center static">{item.dateAddStr}</div>
                                                <div className="text-center static">{item.dueDateStr}</div>
                                                <div className="text-center static">{item.totalPrice}</div>
                                                
                                                <div className="text-right static small">
                                                    <a target="_blank" href={SERVER_URL + "/generate/invoice/" + item.invoiceID}><img title="tisk faktury" className="print-icon" src={notes} /></a> 
                                                
                                                </div>
                                            </div>
                                                    
                                        )
                                        
                                    })} 

                                                        
                                    {data.allCustomerInvoices && data.allCustomerInvoices.length == 0 ?
                                        <NoItems text={"Momentálně se zde nenachází žádné faktury."} />
                                    :
                                        <div className="text-center p-5">
                                            <button className="btn btn-primary" onClick={() => fetchMore({
                                                variables: {
                                                    offset: data.allCustomerInvoices.length
                                                },
                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                    if (!fetchMoreResult) return prev;
                                                    return Object.assign({}, prev, {
                                                        allCustomerInvoices: [...prev.allCustomerInvoices, ...fetchMoreResult.allCustomerInvoices]
                                                    });
                                                }
                                            })}> Načíst další faktury </button>
                                        </div> 

                                    }
                                    
                                </div>
                            </div>
                        </div>
                        );
                    }
                }
            </Query>

                    
                
        );

    }

}

export default withApollo(Customer);