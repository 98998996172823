/**
 * Třída pro formátování textu
 */

class TextFormat{

    constructor(parent,selRange,selectedText){
        this.parent = parent;
        this.selRange = selRange;
        this.selectedText = selectedText;
    }

    setBold(iframeNode,highlight){
        return this.setTag("strong",iframeNode,null,highlight);
    }

    setItalic(iframeNode,highlight){
        return this.setTag("em",iframeNode,null,highlight);
    }

    setLink(iframeNode,attr,highlight){
        return this.setTag("a",iframeNode,attr,highlight);
    }

    setTag(type,iframeNode,parameters,highlight){

        var param = "";
        if(parameters){
            for(let i in parameters){
                param += i + ' = '+ '"' + parameters[i] + '" ';
            }
        }
        
        var data    = this.getSelectedData(iframeNode);
        var newText = "";

        if(!data.range.collapsed){

            var sContainer = data.range.startContainer;
            var eContainer = data.range.endContainer;

            var cText      = sContainer.textContent;
            var eText      = eContainer.textContent;

            var leftPart   = cText.substr(0, data.range.startOffset);
            var centerPart = cText.substr(data.range.startOffset);
            var rightPart  = eText.substr(data.range.endOffset);

            if(sContainer.previousSibling){
                leftPart = this.getPreviousSibling(highlight,sContainer.previousSibling) + leftPart;
            }

            if(sContainer.parentElement && sContainer.parentElement.localName != "p"){
                leftPart = this.getParent(highlight,type,leftPart,sContainer.parentElement,true,false);
            }

            if(sContainer.nextSibling){
                centerPart = centerPart + this.getNextSibling(highlight,type,sContainer.nextSibling,eContainer,data.range.endOffset);
                this.stop = false;
            }

            if(data.sameRange){
                centerPart = data.sText;
                this.stop = true;
            } 

            if(sContainer.parentElement && sContainer.parentElement.localName != "p"){
                centerPart = this.getParent(highlight,type,centerPart,sContainer.parentElement,false,true,eContainer,data.range.endOffset);
                this.stop = false;
            }else{
                this.stop = false;
            }

            if(eContainer.nextSibling){
                rightPart = rightPart + this.getNextSibling(highlight,type,eContainer.nextSibling);
            }

            if(eContainer.parentElement && eContainer.parentElement.localName != "p"){
                rightPart = this.getParent(highlight,type,rightPart,eContainer.parentElement,false,true);
            }

            if(highlight){
                newText = leftPart + centerPart + rightPart;
            }else{
                newText = leftPart + "<" + type + (param != "" ? " " + param : "") + ">" + centerPart + "</" + type + ">" + rightPart;
            }             

            if(type == "em")newText = newText.replace(/<\/em><em>/g,"").replace(/<\/strong><strong>/g,"");
            if(type == "strong")newText = newText.replace(/<\/strong><strong>/g,"").replace(/<\/em><em>/g,"");

            if(highlight){
                if(type == "em")newText = newText.replace(/<em><\/em>/g,"");
                if(type == "strong")newText = newText.replace(/<strong><\/strong>/g,"");
            }

            this.stop = false;

        }
        
        return newText;
    }

    getParent(highlight,type,newText,parent,prevSibling, nexSibling, eContainer,endOffset){
        
        var previousSibling = "";
        var nextSibling = "";
        var attr = this.getElementAttributes(parent);

        if(highlight && type == parent.localName && eContainer){
            newText = newText;
        }else{
            if(newText)newText = "<" + parent.localName + (attr != "" ? " " + attr : "") + ">" + newText + "</" + parent.localName + ">";
        } 

        if(parent.previousSibling && prevSibling && !this.stop){
            previousSibling = this.getPreviousSibling(highlight,parent.previousSibling);
        }

        if(parent.nextSibling && nexSibling && !this.stop){
            nextSibling = this.getNextSibling(highlight,type,parent.nextSibling,eContainer,endOffset);
        }

        newText = previousSibling + newText + nextSibling;

        if(parent.parentElement && parent.parentElement.localName != "p"){
            newText = this.getParent(highlight,type,newText,parent.parentElement,prevSibling, nexSibling, eContainer,endOffset);
        }

        return newText;

    }

    getPreviousSibling(highlight,previousSibling){

        var content = "";
        if(previousSibling.nodeName == "#text"){
            content = previousSibling.textContent;
        }else{
            content = previousSibling.outerHTML;
        }

        if(previousSibling.previousSibling){
            return this.getPreviousSibling(highlight,previousSibling.previousSibling) + content;
        }

        return content;
    }

    getNextSibling(highlight,type,nextSibling,eContainer,endOffset){

        var content = "";
        if(nextSibling === eContainer){
            content += nextSibling.textContent.substr(0, endOffset);
            this.stop = true;
        
        }else{
            if(nextSibling.nodeName == "#text"){
                content = nextSibling.textContent;
            }else{
                content = nextSibling.outerHTML;
            }
        }

        if(eContainer && !this.stop){
            if(nextSibling.nodeName != "#text"){
                var attr = this.getElementAttributes(nextSibling);
                if(nextSibling.localName == type){
                    content = this.getChildrens(type,nextSibling.childNodes,eContainer,endOffset);
                }else{
                    content = "<" + nextSibling.localName + (attr != "" ? " " + attr : "") + ">" + this.getChildrens(type,nextSibling.childNodes,eContainer,endOffset) + "</" + nextSibling.localName + ">";
                }
            }else{
                content = nextSibling.textContent;
            }
        }

        if(nextSibling.nextSibling && !this.stop){
            return content + this.getNextSibling(highlight,type,nextSibling.nextSibling,eContainer,endOffset);
        }

        return content;
    }

    getChildrens(type,childNodes,eContainer,endOffset){
        
        var content = "";
        for(let val of childNodes){
            if(!this.stop){
                if(val === eContainer){
                    content += val.textContent.substr(0, endOffset);
                    this.stop = true;
                
                }else{
                    if(val.nodeName != "#text"){
                        var attr = this.getElementAttributes(val);
                        if(val.localName == type){
                            content += this.getChildrens(type,val.childNodes,eContainer,endOffset);
                        }else{
                            content += "<" + val.localName + (attr != "" ? " " + attr : "") + ">" + this.getChildrens(type,val.childNodes,eContainer,endOffset) + "</" + val.localName + ">";
                        }
                        
                    }else{
                        content += val.textContent;
                    }
                }
            }
        }

        return content;
    }

    highlightControls(e,iframeNode){

        var idoc     = iframeNode.contentDocument || iframeNode.contentWindow.document; 
        var selObj   = idoc.getSelection();
        var selRange = selObj.getRangeAt(0);

        var highlight = {
            hBold:false,
            hItalic:false,
            hLink:false,
        }

        var tags  = "";
        if(selRange.startContainer.parentElement){
            tags = this.findParents(tags,selRange.startContainer.parentElement);
        }

        if(tags != ""){
            tags = tags.split("-");
            for(let val of tags){
                if(val == "strong")highlight.hBold = true;
                if(val == "em")highlight.hItalic = true;
                if(val == "a")highlight.hLink = true;
            }
        }

        this.parent.setState(highlight);

    }

    findParents(tags,element){

        if(element.localName != "p"){
            if(tags != "")tags += "-" + element.localName;
            else tags = element.localName;

            if(element.parentElement){
                tags = this.findParents(tags,element.parentElement);
            }
        }

        return tags;
    }

    getSelectedData(iframeNode){

        var idoc = iframeNode.contentDocument || iframeNode.contentWindow.document; // ie compatibility

        var selObj = idoc.getSelection();
        var selRange = selObj.getRangeAt(0);

        if(this.selRange)selRange = this.selRange;

        var sameRange = false;
        if(selRange.startContainer === selRange.endContainer)sameRange = true;

        return {
            sText: (this.selectedText ? this.selectedText : selObj.toString()),
            range: selRange,
            sameRange:sameRange
        }

    }

    getElementAttributes(element){

        var attr = "";

        if(element.attributes.length > 0){  
            for(let i = 0; i < element.attributes.length;i++){
                attr += element.attributes[i].name + ' = '+ '"' + element.attributes[i].value + '" ';
            }  
        }

        return attr;
    }

}

export default TextFormat;