import React,{ Component,Fragment } from 'react';
import HeadlineElement from './HeadlineElement';
import ColElement from './ColumnsElement';
import AddElement from './AddElement';
import ParagraphElement from './ParagraphElement';
import remove from '../../../Public/Images/remove.svg';
import parent from '../../../Public/Images/parent.svg';


class ColumnsElement extends Component{

    constructor(props){
        super(props);
    }

    render(){

        const {element,lastIndexes,cm,showHelpers,iframeNode,fullWidth} = this.props;

        var parentIndexes = "";
        
        var indexes = lastIndexes.split("-");
        if(indexes.length > 2){
            indexes.pop();
            parentIndexes = indexes.join("-");
        }

        return (
            <div className="cm-columns row">

                {showHelpers && element.showMenu &&
                    <div className="cm-menu cm-col" onClick={(e) => e.stopPropagation()}>
                        <div className="form-inline">
                            {element.cols.length == 0 && 
                                <div className="form-group mr-1">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text" htmlFor="inputGroupSelect02">Sloupců:</label>
                                        </div>
                                        <select className="form-control" name="section-padding" onChange={(e) => cm.setColumnsColNumber(e,lastIndexes)}>
                                            <option value="0"> -- </option>
                                            <option value="1"> 1 </option>
                                            <option value="2"> 2 </option>
                                            <option value="3"> 3 </option>
                                            <option value="4"> 4 </option>
                                            <option value="5"> 5 </option>
                                            <option value="6"> 6 </option>
                                        </select>
                                    </div>
                                </div> 
                            }
                            {parentIndexes != "" &&
                                <div className="form-group">
                                    <div className="item-container" onClick={(e) => cm.selectElement(e,"column",parentIndexes)}>
                                        <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                    </div>
                                </div>
                            }
                            <div className="form-group">
                                <div className="item-container" onClick={() => cm.removeElement(lastIndexes)}>
                                    <img className="remove-icon" src={remove} alt="Odstranit sloupce" title="Odstranit sloupce" />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }

                <div className={(fullWidth == 1 ? "container-fluid"  : "container")}>
                    <div className="row">
                        {element.cols.length > 0 && element.cols.map((item,index) => (
                            <div key={index} className="col cm-column" rel={index}>
                                {showHelpers && item.showMenu &&
                                    <div className="cm-menu cm-col" onClick={(e) => e.stopPropagation()}>
                                        <div className="form-inline">
                                            <div className="form-group">
                                                <AddElement lastIndexes = {lastIndexes + "-" + index} element={item} cm={cm}  />
                                            </div>
                                            <div className="form-group">
                                                <div className="item-container" onClick={(e) => cm.selectElement(e,"columns",lastIndexes)}>
                                                    <img className="remove-icon" src={parent} alt="Nadřazený element" title="Nadřazený element" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="cm-col-content" onClick={(e) => !element.showMenu && cm.selectElement(e,"column",lastIndexes + "-" + index)}>
                                    {item.elements && item.elements.map((elmItem,elmIndex) => {
                                                    
                                        if(elmItem.headline){
                                            return(<HeadlineElement key = {elmIndex} cm={cm} element = {elmItem.headline} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} showHelpers = {showHelpers} />)
                                        }
                                        if(elmItem.columns){
                                            return(<ColElement fullWidth = {fullWidth} iframeNode = {iframeNode} key = {elmIndex} cm={cm} element = {elmItem.columns} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} showHelpers = {showHelpers} />)
                                        }
                                        if(elmItem.paragraph){
                                            return(<ParagraphElement iframeNode = {iframeNode} key = {elmIndex} cm={cm} element = {elmItem.paragraph} lastIndexes = {lastIndexes + "-" + index + "-" + elmIndex} showHelpers = {showHelpers} />)
                                        }
                                        
                                    })}
                                </div>
                                {showHelpers && <div className={"cm-col-border" + (item.showMenu ? " cm-selected" : "")} onClick={(e) => cm.selectElement(e,"column",lastIndexes + "-" + index)} ></div>}

                            </div>
                        ))}

                        {showHelpers &&<div className={"cm-col-border" + (element.showMenu ? " cm-selected" : "")}></div>}
                    </div>
                </div>
                
            </div>
        );

    }
}

export default ColumnsElement;