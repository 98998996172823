/*
    Přidání kategorie
*/
import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Modal from 'react-modal';
import {ADD_EDIT_VIDEO,GET_VIDEOS} from '../Queries/index.js';
import { withApollo } from 'react-apollo';
import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import VideoLib from '../Library/video';
import TinyMCEBasic from '../../TinyMCEFileManager/Components/TinyMCEBasic';


const INITIAL_STATE = {
    err:"",
    formData:{
        videoID:0,
        categoryID:0,
        langs:[],
        selectedLang:'cz'
    },
    allLanguageMutations:[],
    allFilterCategories:[]
}

class AddEditVideo extends Component{

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
        
        this.videoLib = new VideoLib(this); 
    }

    componentDidMount(){
        this.videoLib.getData();
    }

    
    

    render(){

        var {err} = this.state;

        return(

            <Mutation 
                mutation={ADD_EDIT_VIDEO}
                errorPolicy = "all"
                refetchQueries = {(data)=>{
                    return [{
                        query:GET_VIDEOS,
                        variables: { lang:"cz" }
                    }]
                }}
                update = {async (cache, response) => {

                    let notify = new Notification();

                    if(this.props.selectedVideoID && this.props.selectedVideoID != 0) notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    else notify.setNotification(cache,'Úspěšně přidáno',true,false);

                }}

                onCompleted = {() => {
                    this.props.openCloseModal(false);
                }}
                
            >
            {
                (addEditVideo,{loading, error}) => {

                    const {categoryID,active,selectedLang} = this.state.formData;
                    const {allLanguageMutations,allCategories} = this.state;
                    
                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <Modal
                            className="Modal__Bootstrap modal-dialog modal-lg"
                            closeTimeoutMS={150}
                            isOpen={true}
                            onRequestClose={() => this.props.openCloseModal(false)}
                        >
                            <div className="modal-content">
                                <div className="modal-header d-flex align-items-center">
                                    <h4 className="modal-title">{(this.props.selectedVideoID && this.props.selectedVideoID != 0 ? "Úprava videa" : "Přidání videa")}</h4>
                                    <div className="ml-auto">
                                        <select className="form-control" name="selectedLang" onChange={(e) => this.videoLib.formHandle(e)}>
                                            {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                <option key={index} value={item.suffix}> {item.suffix} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="button" className="close ml-3" onClick={() => this.props.openCloseModal(false)}>                                    
                                        <span aria-hidden="true">x</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {err ?
                                        <Error text={err} />
                                    :
                                        <Fragment>
                                            {!loading ?
                                            <div className="row">
                                                <div className="col-12 col-sm-4">
                                                    <label className="input-label">*Kategorie</label>
                                                    <div className="form-group">
                                                        
                                                        <select value={categoryID} className="form-control" name="categoryID" onChange={(e) => this.videoLib.formHandle(e)}>

                                                            <option key={0} value={0}> --- vyberte kategorii --- </option>
                                                            {allCategories && allCategories.map((item,index) => (
                                                                <option key={index} value={item.categoryID}> {item.name} </option>
                                                            ))}

                                                        </select>

                                                    </div>
                                                </div>                  
                                                <div className="col-12 col-sm-4">
                                                    <label className="input-label">Název videa ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="name" value={this.videoLib.getLangValue("name")} onChange={(e) => this.videoLib.formLangHandle(e)} />
                                                    </div>
                                                </div>              
                                                <div className="col-12 col-sm-8">
                                                    <label className="input-label">URL videa ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <input className="form-control" type="text" name="url" value={this.videoLib.getLangValue("url")} onChange={(e) => this.videoLib.formLangHandle(e)} />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <label className="input-label">Text emailu ({selectedLang})</label>
                                                    <div className="form-group">
                                                        <TinyMCEBasic 
                                                            name="emailText"
                                                            value={this.videoLib.getLangValue("emailText")}
                                                            OnEditorChange={(content) => this.videoLib.formLangHandleEditor(content,"emailText")}
                                                        />
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            :

                                            <Loading />  

                                            }  
                                        </Fragment>
                                    }
                                            
                                </div>
                                <div className="modal-footer"> 
                                    {!loading && <button type="button" className="btn btn-primary flex-fill" onClick={() => this.videoLib.addVideo(addEditVideo)}>{(this.props.selectedVideoID && this.props.selectedVideoID != 0 ? "Upavit" : "Přidat")}</button>}
                                    <button type="button" className="btn btn-danger" onClick={() => this.props.openCloseModal(false)}>Zavřít</button>
                                </div>
                            </div>
                        </Modal>
                    );

                }
            }
            </Mutation>
        
        );

    }

}

export default withApollo(AddEditVideo);