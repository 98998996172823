/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import {GET_MARKETING,GET_MARKETINGS} from '../Queries/marketing';
import Notification from '../../../Library/notification';
import {MARKETING_IMAGE_URL,SERVER_URL} from '../../../Config/index';

class Marketing {

    constructor(parent){
        this.parent = parent;
    }

    
    /**
     * Přidání/Editace
     * @param {*} addMarketing funkce z apollo, pomocí které se posílají data na server
     */

    addMarketing = (addMarketing) => {

        const data = this.parent.state.formData;
        let notify = new Notification();   
        
        if((data.photo && !this.parent.props.selectedMarketingID) || (this.parent.props.selectedMarketingID && this.parent.props.selectedMarketingID != 0)){ 

            if((data.photoPortrait && !this.parent.props.selectedMarketingID) || (this.parent.props.selectedMarketingID && this.parent.props.selectedMarketingID != 0)){ 
                       
                let langs = [];
                for(const lang of data.langs){
                    langs.push({
                        title:      lang.title,
                        text:       lang.text,
                        buttonText: lang.buttonText,
                        buttonUrl:  lang.buttonUrl,
                        lang:       lang.lang
                    });
                }

                addMarketing({
                    variables:{
                        marketingID:data.marketingID,
                        photo:data.photo,
                        photoPortrait:data.photoPortrait,
                        showInBlog:parseInt(data.showInBlog),
                        showInAllArticles:parseInt(data.showInAllArticles),
                        langs
                    }
                });

            }else{
                notify.setNotification(null,'Nevybrali jste fotku na výšku.',false,true,this.parent.props.client);
            }
                          
        }else{
            notify.setNotification(null,'Nevybrali jste fotku na šířku.',false,true,this.parent.props.client);
        }
               
    } 

    
    /**
     * při inicializaci vložení nebo úpravy se vytáhnou potřebná data
     */

    async getData(){

        var marketingID = 0;
        if(this.parent.props.selectedMarketingID)marketingID = this.parent.props.selectedMarketingID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_MARKETING,
            errorPolicy:"all",
            variables:{marketingID:marketingID}, 
            fetchPolicy: 'network-only'
        });
        
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        //kdyz upravujeme zaznam
        if(data.data){                  
            if(data.data.marketingWithLangs){    
                let langs = this.completeLangsArray(data.data.marketingWithLangs.langs,data.data.allLanguageMutations);    
                
                var mainPhoto = "";
                var mainPhotoPortrait = "";

                if(data.data.marketingWithLangs.photo)
                    mainPhoto = SERVER_URL + "/" + MARKETING_IMAGE_URL + "/maxi_" + data.data.marketingWithLangs.photo;

                if(data.data.marketingWithLangs.photoPortrait)
                    mainPhotoPortrait = SERVER_URL + "/" + MARKETING_IMAGE_URL + "/maxi_" + data.data.marketingWithLangs.photoPortrait;
                
                this.parent.setState({
                    apolloError:"",
                    selectedMarketingID:marketingID,
                    mainPhoto,
                    mainPhotoPortrait,
                    allLanguageMutations: data.data.allLanguageMutations,
                    formData:{...this.parent.state.formData,
                        marketingID:    data.data.marketingWithLangs.marketingID,
                        showInBlog:     data.data.marketingWithLangs.showInBlog,
                        showInAllArticles: data.data.marketingWithLangs.showInAllArticles,
                        langs:langs
                    }
                });
            }else{
                this.parent.setState({ apolloError:""});
            }
        }

        //kdyz pridavame zaznam
        if(marketingID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);            
            
            this.parent.setState({
                allLanguageMutations: data.data.allLanguageMutations,
                formData:{...this.parent.state.formData,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    name:"",
                    price:"",
                    title:"",
                    text:"",
                    buttonText:"",
                    buttonUrl:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { allMarketings } = cache.readQuery({ query: GET_MARKETINGS,variables:variables });
        
        if(!(selectedID && selectedID != 0)){

            const arr = [response.data.addEditMarketing,...allMarketings]   ;
          
            cache.writeQuery({ 
                query:GET_MARKETINGS,
                variables:variables,
                data:{
                    allMarketings: arr
                } 
            });

        }else{

            var arr = [...allMarketings];
            
            arr.forEach((item,index) => {
                
                if(item.marketingID == response.data.addEditMarketing.marketingID){
                    arr[index].marketingID       = response.data.addEditMarketing.marketingID;
                    arr[index].title             = response.data.addEditMarketing.title;
                    arr[index].text              = response.data.addEditMarketing.text;
                    arr[index].lang              = response.data.addEditMarketing.lang;

                    if(response.data.addEditMarketing.photo){
                        arr[index].photo = response.data.addEditMarketing.photo;
                    }
                }

            });
            cache.writeQuery({ 
                query:GET_MARKETINGS,
                variables:variables,
                data:{
                    allMarketings: arr
                } 
            });
        }
    }

    /**
     * Otevře obrázke, zpracuje a zobrazí jeho náhled
     * @param {*} e 
     */

    openImage(e,photoType,mainPhotoType){
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;
            this.parent.setState({
                [mainPhotoType]:dataURL,
                formData:{...this.parent.state.formData,[photoType]:photo}
            })
        };
        reader.readAsDataURL(photo);
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteMarketings.split(",");

        const { allMarketings } = cache.readQuery({ query: GET_MARKETINGS ,variables});
        var arr = [...allMarketings];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.marketingID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_MARKETINGS,
            variables,
            data:{
                allMarketings: [...arr]
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }
    /**
     * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName){
        
        let langs       = this.parent.state.formData.langs;

        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (propertyName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }


}

export default Marketing;