/**
 *  Pomocná třída s obecnými funkcemi
 */

class Helper{

    getApolloErrorText(error){

        error = JSON.stringify(error);
        error = JSON.parse(error);

        if(error.graphQLErrors[0])return error.graphQLErrors[0].message;
        if(error.networkError && error.networkError.result.errors){
            return error.networkError.result.errors[0].message;
        }

    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

}

export default Helper;